<template>
  <button class="c-button" :class="classes" :disabled="disabled" :type="submit ? 'submit' : 'button'" :value="text"
    @click.prevent="handleClick">
    {{ text }}
  </button>
</template>
<script>
export default {
  name: 'ButtonDefault',
  props: {
    text: {
      type: String,
      required: true
    },
    size: {
      type: String,
      required: false,
      default: 'medium'
    },
    type: {
      type: String,
      required: false,
      default: 'primary'
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    submit: {
      type: Boolean,
      default: false
    },
    fullsize: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      disabled: this.$props.isDisabled
    }
  },
  computed: {
    classes() {
      let fullsize
      let buttonsize = ''
      let buttontype = ''
      let buttondisable = ''
      const buttonlink = ''
      switch (this.$props.size) {
        case 'esmall':
          buttonsize += 'c-button--e-sm '
          break
        case 'small':
          buttonsize += 'c-button--sm '
          break
        case 'medium':
          buttonsize += 'c-button--md '
          break
        case 'large':
          buttonsize += 'c-button--lg '
          break
        case 'xlarge':
          buttonsize += 'c-button--xl '
          break
        case 'link':
          buttontype += 'a-button--link ';
          break;
        default:
          buttonsize = ''
      }
      switch (this.$props.type) {
        case 'primary':
          buttontype += 'c-button--primary '
          break
        case 'secondary':
          buttontype += 'c-button--secondary '
          break
        case 'special-secondary':
          buttontype += 'c-button--special-secondary '
          break
        default:
          buttontype = ''
      }
      if (this.$props.isDisabled) {
        buttondisable = 'c-button--disabled '
      }
      if (this.$props.fullsize) {
        fullsize = 'c-button--fullsize '
      }

      return buttonsize + buttontype + buttondisable + buttonlink + fullsize
    }
  },
  watch: {
    isDisabled() {
      this.disabled = this.isDisabled
    }
  },
  methods: {
    handleClick() {
      this.$emit('clicked')
    }
  }
}
</script>
<style lang="scss" scoped>
.c-button {
  align-items: center;
  border: 1px solid $alfa-primary;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  font-family: $montserrat-semi-bold;
  height: 48px;
  justify-content: center;
  // margin: auto;
  padding: 0 40px;
  width: fit-content;

  &--e-sm {
    font-size: 16px;
    min-width: rem(142);
  }

  &--sm {
    font-size: 18px;
    min-width: rem(162);
  }

  &--md {
    font-size: 18px;
    padding: 0 46px;
    min-width: rem(162);
  }

  &--lg {
    font-size: 22.75px;
    padding: 0 77px;
    min-width: rem(244);
  }

  &--xl {
    font-size: 30.78px;
    padding: 0 77px;
    min-width: rem(400);
  }

  &--primary {
    color: white;
    background-color: $alfa-primary;

    &:hover {
      background-color: rgba($alfa-primary, .8);
      border-color: rgba($alfa-primary, .8);
    }
  }

  &--secondary {
    color: $alfa-primary;
    border: 1px solid $alfa-primary;
    background-color: transparent;

    &:hover {
      color: white;
      background-color: $alfa-primary;
    }
  }

  &--special-secondary {
    background-color: $alfa-third;
    border: none;
    font-size: rem(22.78px);
    min-width: 244px;
    padding: 0 13px;

    &:hover {
      color: rgba($alfa-primary, .8);
      background-color: rgba($alfa-third, .8);
    }
  }

  &--disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &--fullsize {
    min-width: 100%;
  }

  &--link {
    font-family: $montserrat-regular;
    border: 0;
    background: none;

    &:hover {
      color: rgba($alfa-primary, .8);
    }
  }

}
</style>
